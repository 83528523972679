<template>
  <!-- <div> -->
  <div class="page_wrapper page-break">
    <div class="logo">
      <img src="../../assets/oinova/logo_oi.png" alt="logo Oi" />
    </div>
    <!-- /logo -->

    <div class="title">
      <h2>
        Oi, <span class="title_name">{{ item.customerData.name }}</span
        >, seu código de barras chegou
      </h2>
    </div>
    <!-- /title -->

    <div class="subtitle">
      <h3>
        Use as informações abaixo pra fazer o pagamento. Caso haja incidência de
        multa e juros, o valor será cobrado após o pagamento de cada uma das
        contas.
      </h3>
    </div>
    <!-- /subtitle -->

    <div class="column">
      <div>
        <h5>Vencida em:</h5>
        <h4>{{ item.dueDate | dataF }}</h4>
      </div>

      <div>
        <h5>Valor:</h5>
        <h4>R$ {{ item.currentValue | dinheiro }}</h4>
      </div>
    </div>
    <!-- /column -->

    <!-- /bar_code -->

    <div class="bar_code">
      <svg id="barcode"></svg>
      <h4>{{ dados.digitableLine | linhadigi }}</h4>
    </div>
    <!-- /bar_code -->

    <div class="line"></div>

    <div class="expiry">
      <p>
        Esse documento é válido para pagamento até
        <span>{{ item.dueDate | dataF }}</span
        >.
      </p>
      <h5>
        Saiba mais em
        <a href="https://negociacao.oi.net.br/" target="_blank">Oi Negocia</a>.
      </h5>
    </div>
    <!-- /expiry -->

    <footer>
      <p>
        Obrigado por escolher a Oi. © Copyright 2019 - Oi.com.br - Todos os
        direitos reservados.
      </p>
      <hr />
      <p>A Oi respeita a sua privacidade e é contra o spam na rede.</p>
    </footer>
  </div>
  <!-- /page_wrapper -->
  <!-- </div> -->
</template>

<script>
import JsBarcode from "jsbarcode";
import {api} from '@/conf/api'
export default {
  name: "BolOiFatura",
  components: {},
  filters: {
    dinheiro: function(value) {
      return parseFloat(value)
        .toFixed(2)
        .replace(".", ",");
    },
    dataF: function(d2) {
      let d = d2.substr(0, 10);
      return d.substr(8, 2) + "/" + d.substr(5, 2) + "/" + d.substr(0, 4);
    },
    linhadigi: function (ld) {
      if (ld.length != 48) return ld;
    let cb1 = ld.substr(0, 11);
    let cb2 = ld.substr(12, 11);
    let cb3 = ld.substr(24, 11);
    let cb4 = ld.substr(36, 11);

    let d1 = ld.substr(11, 1);
    let d2 = ld.substr(23, 1);
    let d3 = ld.substr(35, 1);
    let d4 = ld.substr(47, 1);

    return cb1+'-'+d1+' '+cb2+'-'+d2+' '+cb3+'-'+d3+' '+cb4+'-'+d4
      
    }
  },
  data() {
    return {
      dados: null,
      item: null,
    };
  },
  async beforeMount() {
    this.$store.dispatch("setMostraBotoes", { imprimir: true, voltar: false });
    let resp = this.$store.state.divida_oi_nova_resp;
    // console.log(resp);
    this.dados = resp.resp.dados;
    this.item = resp.item;
    console.log(this.item, this.dados);
    //-----------------------------------------------------
   let divida_atual = this.$store.state.divida_atual
   let cgc_aqui = divida_atual.cpcgc
   let credor = ''
   if(cgc_aqui =='04164616000158') credor = 'OI MOVEL'
   if(cgc_aqui =='05423963000111') credor = 'OI TV DTH'
   if(cgc_aqui =='33000118000179') credor = 'OI FIXO'
   if(cgc_aqui =='33000118000190') credor = 'BRT - R2'

   let dados = {
      credor,
      cpf: divida_atual.cpcpfcgc,
      contrato: divida_atual.contrato,
      valor: this.item.installmentAmountCharged,
      venc: this.meuvenc,
      fatura: null,
      codBarras: this.dados.barCode,
      L_dig: this.dados.digitableLine,
      remessa: null,
      parcelas_faltam: null,
      email: null,
      valor_entrada: null,
      valor_parcela: null,
    };
    await api.post(`logs/gravaacordosomente`, dados);
   //-----------------------------------------------------
  },
  async mounted() {
    //JsBarcode("#barcode", "84620000002285000240101888831034976875040004");

    JsBarcode("#barcode", this.dados.barCode, {
      format: "ITF",
      width: 1.6,
      height: 80,
      margin: 0,
      textMargin: 2,
      font: "IBM Plex Mono",
      fontSize: 22,
      fontOptions: "500",
      color: "blue",
      displayValue: false
    });
  },
};
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: monospace;
  line-height: 1.2;
}
.page_wrapper {
  width: 18cm;
  margin: 50px auto 0px auto;
}
.page_wrapper .line {
  margin-top: 30px;
  color: #dbdbdb;
  background-color: #dbdbdb;
  opacity: 1;
  width: 100%;
  height: 3px;
}
.logo {
  margin-bottom: 30px;
  display: block;
}
.title h2 {
  font-size: 24pt;
  color: #303030;
  font-weight: bold;
}
.title_name {
  font-size: 23pt;
  text-transform: uppercase;
}
.subtitle {
  margin-top: 20px;
  display: block;
}
.subtitle h3 {
  font-size: 17pt;
  font-weight: 500;
  color: #3f3f3f;
  letter-spacing: 0px;
}
.column {
  display: flex;
  justify-content: space-between;
  width: 70%;
  margin-top: 20px;
}
.column div {
}
.column div h5 {
  color: #909090;
  font-weight: 400;
  font-size: 15pt;
}
.column div h4 {
  color: #222222;
  font-size: 25pt;
}
.bar_code {
  margin-top: 30px;
}
.expiry {
  margin-top: 20px;
}
.expiry p {
  color: #909090;
  font-size: 14pt;
  font-weight: 400;
}
.expiry p span {
  font-weight: bold;
  text-decoration: underline;
}
.expiry h5 {
  color: #222222;
  font-weight: 500;
  font-size: 18pt;
  margin-top: 20px;
}
.expiry h5 a {
  color: #dc2482;
  text-decoration: none;
}
footer {
  margin-top: 20px;
  padding: 3rem 0px;

  background-color: #f0f0f0;
}
footer p {
  font-size: 13pt;
  text-align: center;
  color: #909090;
}
footer hr {
  color: #d2d2d2;
  background-color: #d2d2d2;
  margin: 15px 0;
}
.page-break {
  page-break-after: always !important;
}

@media print {
  @page {
    size: A4 portrait;
  }

  * {
    print-color-adjust: exact !important;
  }
}
</style>
